import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <>
      <SEO title="Personal" />
      <Layout>
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
              <div>
                <h2 className="text-3xl leading-9 font-extrabold text-warm-grey-900 sm:text-4xl sm:leading-10">
                  Personal
                </h2>
                <p className="mt-3 max-w-3xl text-lg leading-7 text-warm-grey-500">
                  Här finner du vilka personer som jobbar på Förskolan
                  Brandbilen.
                </p>
                <div className="mt-8 sm:flex">
                  <div className="rounded-md shadow">
                    <Link
                      to="/kontakt"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Kontakta oss
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/om-brandbilen"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-bb-red-700 bg-bb-red-100 hover:text-bb-red-600 hover:bg-bb-red-50 focus:outline-none focus:shadow-outline focus:border-bb-red-300 transition duration-150 ease-in-out"
                    >
                      Läs mer om Brandbilen
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">
                    Förskollärare och rektor
                  </p>
                  <p className="text-base">Linda Stenberg</p>
                </div>
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">Barnskötare</p>
                  <p className="text-base">Susanne Östlund</p>
                </div>
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">
                    Barnskötare och förskollärarassistent
                  </p>
                  <p className="text-base">Maria Magnusson</p>
                </div>
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">Förskollärare</p>
                  <p className="text-base">Emelie Brorsson</p>
                </div>
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">Barnskötare</p>
                  <p className="text-base">Emelie Forsberg</p>
                </div>
                <div className="col-span-1 flex flex-col items-center py-8 px-8 bg-warm-grey-50">
                  <p className="text-xl font-bold py-2 mt-2">Kökspersonal</p>
                  <p className="text-base">Anna Salomonsson</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page
